.footer {
  padding: 20px 10px;
  background: rgba(var(--card-rgb), var(--layout-color));
  text-align: left;
  width: 100%;
  bottom: 0;
  margin-top: 5rem;
}

.footerLinks {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  flex-wrap: wrap;
}

.footerColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footerColumnTitle {
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.footerLink {
  margin-bottom: 0.75rem;
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease;
}

.footerDivider {
  border-bottom: 1px solid rgba(var(--footer-divider), 0.7);
  margin-top: 2rem;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.footerText {
  margin-top: 0;
  text-align: center;
  width: 100%;
}

.footerLink.active,
.footerLink:hover {
  text-decoration: underline;
  color: rgb(var(--hover-color));
}
