.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 6rem;
  min-height: 100vh;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(25%, auto));
  justify-content: center;
  gap: 2rem;
  max-width: 100%;
  width: var(--max-width);
  margin-top: auto;
}

.card {
  padding: 1rem 1.2rem;
  border-radius: var(--border-radius);
  background: rgba(var(--card-rgb), 0.2);
  border: 1px solid rgba(var(--card-border-rgb), 0);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
}

.card span {
  display: inline-block;
  transition: transform 200ms;
}

.card h2 {
  font-weight: 500;
  margin-bottom: 0.7rem;
}

.card p {
  margin: 0;
  opacity: 0.6;
  font-size: 0.9rem;
  line-height: 1.5;
}

.redLetter {
  color: red;
  font-style: italic;
  letter-spacing: 0.15rem;
}

.linkColor {
  font-weight: bold;
  letter-spacing: 0.15rem;
}

.sectionContainer {
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.sectionDivider {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}

.sectionDividerContact {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;

  flex-direction: row;
}

.section {
  text-align: justify;
  padding: 2.5rem;
  max-width: 80rem;
  min-width: 25rem;
  border-radius: var(--border-radius);
  background: rgba(var(--card-rgb), 0.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.section h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.section p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 0;
}

.sectionSeparator {
  border: 1;
  width: 100%;
  height: 1px;
  color: rgba(20, 20, 20, 0.5);
  margin: 4rem 0;
}

@keyframes rotateIcon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.imageRotate {
  animation: rotateIcon 4s linear infinite;
}

.imageWrapper {
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: left;
  align-items: right;
  flex-shrink: 0;
  flex: 1;
}

.backgroundImageWrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.screenshotWrapper {
  display: flex;
  max-width: 80rem;
  width: auto;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-left: 5rem;
  margin-right: 5rem;
  border-radius: 8px;
  overflow: hidden;
}

/* FormatUtils */
.defaultColor {
  color: rgb(var(--hover-color));
}

.link {
  color: rgb(var(--hover-color));
  transition: color 0.3s ease, filter 0.3s ease;
}

.link:hover {
  color: rgb(var(--hover-color));
  text-decoration: underline;
  filter: brightness(1.2);
}

.mark {
  background-color: rgb(var(--hover-color));
  padding: 0.2rem;
  border-radius: 0.2rem;
}

.listItem {
  display: list-item;
  list-style-position: outside;
  margin-left: 1.5rem;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

.changeColours {
  filter: sepia(1) hue-rotate(var(--hueRotate)) saturate(var(--saturate));
}

@media (max-width: 700px) {
  .responsiveImage {
    width: 13px;
    height: 13px;
  }

  .main {
    padding-top: 3rem;
  }

  .grid {
    grid-template-columns: 1fr;
    text-align: center;
    max-width: 95%;
  }

  .card {
    padding: 1rem;
    width: 100%;
  }

  .card h2 {
    font-size: 1.2rem;
  }

  .card p {
    font-size: 0.8rem;
  }

  .section {
    padding: 1rem;
    max-width: 90%;
    min-width: 0;
  }

  .section h2 {
    font-size: 1.5rem;
  }

  .section p {
    font-size: 0.9rem;
  }

  .sectionContainer {
    flex-direction: column;
    padding: 1rem;
  }

  .sectionDivider {
    flex-direction: column;
    gap: 1rem;
    text-align: left;
  }

  .sectionSeparator {
    margin: 2rem 0;
  }

  .imageWrapper {
    width: 100%;
    justify-content: center;
  }

  .backgroundImageWrapper {
    background-size: cover;
    background-position: center center;
    height: 18rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 701px) and (max-width: 1200px) {
  .responsiveImage {
    width: 20px;
    height: 20px;
  }

  .main {
    padding-top: 4rem;
  }

  .grid {
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    max-width: 90%;
  }

  .card {
    padding: 1.5rem;
    width: 100%;
  }

  .card h2 {
    font-size: 1.5rem;
  }

  .card p {
    font-size: 0.9rem;
  }

  .section {
    padding: 1.5rem;
  }

  .section h2 {
    font-size: 1.8rem;
  }

  .section p {
    font-size: 1rem;
  }

  .sectionSeparator {
    margin: 3rem 0;
  }

  .imageWrapper {
    width: auto;
    justify-content: center;
  }

  .backgroundImageWrapper {
    background-size: cover;
    background-position: center center;
    height: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1201px) {
  .responsiveImage {
    width: 25px;
    height: 25px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@media (hover: hover) and (pointer: fine) {
  .card:hover {
    background: rgba(var(--card-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
  }

  .card:hover span {
    transform: translateX(4px);
  }
}

@media (prefers-reduced-motion) {
  .card:hover span {
    transform: none;
  }
}
