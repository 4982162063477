.navbar {
  position: fixed;
  top: 0rem;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: rgba(0, 0, 0, var(--layout-color));
  backdrop-filter: blur(10rem);
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.2);
  width: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);
}

.navLogo {
  font-size: 3rem;
  color: var(--text-color);
}

.navItems {
  display: flex;
  align-items: center;
}

.navLinks {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.navLinks a {
  font-size: 1.5rem;
  color: var(--text-color);
  margin: 0 1rem;
  padding: 0.5rem 0;
  transition: color 0.3s ease;
}

.navbar .navLinks a.active,
.navbar .navLinks a:hover {
  text-decoration: underline;
  color: rgb(var(--hover-color));
}

.dropdown {
  position: right;
  display: inline-block;
}

.dropdownButton {
  font-size: 1.5rem;
  margin-right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
}

.dropdownContent {
  position: absolute;
  background: rgba(var(--average-color-rgb), 0.96);
  min-width: 10rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);
  z-index: 1;
  right: 0;
  margin-top: 2rem;
}

.dropdownContent a {
  color: var(--text-color);
  padding: 0.5rem 1rem;
  text-decoration: none;
  display: block;
}

@media (max-width: 1200px) {
  .navLinks a:nth-child(7) {
    display: none;
  }
}

@media (max-width: 1100px) {
  .navLinks a:nth-child(6) {
    display: none;
  }
}

@media (max-width: 1000px) {
  .navLinks a:nth-child(5) {
    display: none;
  }
}

@media (max-width: 900px) {
  .navLinks a:nth-child(4) {
    display: none;
  }
  .navLogo {
    font-size: 2.5rem;
  }
  .dropdownContent {
    margin-top: 1.63rem;
  }
}

@media (max-width: 800px) {
  .navLinks a:nth-child(3) {
    display: none;
  }
}

@media (max-width: 700px) {
  .navLinks a:nth-child(2) {
    display: none;
  }
  .navLinks a {
    font-size: 1.25rem;
  }
  .dropdownButton {
    font-size: 1.25rem;
  }
  .navLogo {
    font-size: 2rem;
  }
  .dropdownContent {
    margin-top: 1.53rem;
  }
  .navbar {
    padding: 0.5rem 2rem;
  }
}
@media (max-width: 600px) {
  .navLinks a:nth-child(1) {
    display: none;
  }
}
